import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Services from '../Services/Services';

const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);
  const gotoBtn = () => {
    window.scrollTo({ top: 300, left: 0, behavior: "smooth" });
  };
  const gotoBtnSecond = () => {
    window.scrollTo({ top: 1000, left: 0, behavior: "smooth" });
  };

  const listenToScroll = () => {
    let heightToHidden = 20;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHidden) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  return (
    //        
    <footer id="footer" class="footer  mt-5">
      <div class="footer-main">
        <div class="container">
          <div class="row justify-content-between align-items-center">
            <div class="col-lg-4 col-md-6 footer-widget footer-about">
              <h3 class="widget-title">About Us</h3>
              {/* <img loading="lazy" width="200px" class="footer-logo" src="images/footer-logo.png" alt="Constra"/> */}
              <p className='text-white' style={{ lineHeight: "24px", marginLeft: "18px" }}>We're embarked on a mission to revolutionize the landscape of the real estate field.</p>

            </div>

            <div class="col-lg-4 col-md-6 footer-widget   " style={{ lineHeight: "5px" }}>
              <div className=' ' style={{ lineHeight: "8px" }}>
                <h3 class="widget-title" >CONNECT WITH US  </h3>

              </div>


              <div class=" " >
                <ul className='d-flex  align-items-center' style={{ listStyle: "none" }}>
                  <li  ><a href="https://www.facebook.com/profile.php?id=61555864421463" aria-label="Facebook"><i
                    class="fab fa-facebook-f"></i></a></li>
                  <li style={{ marginLeft: "20px" }}><a href="https://twitter.com" aria-label="Twitter"><i class="fab fa-twitter"></i></a>
                  </li>
                  <li style={{ marginLeft: "20px" }}><a href="https://www.instagram.com/royalbengalrealestate/" aria-label="Instagram"><i
                    class="fab fa-instagram"></i></a></li>
                  <li style={{ marginLeft: "20px" }}><a href="https://www.youtube.com/channel/UC8ZAhM2kngHv7FShPItJLLQ" aria-label="Youtube"><i class="fab fa-youtube"></i></a>
                  </li>

                </ul>
              </div>
              <div class="  " >
                <h3 class="widget-title" >Office Address  </h3>
                <p className='text-white' style={{ lineHeight: "8px", marginLeft: "20px" }}>Al Bada Satwa</p>
                <p className='text-white' style={{ lineHeight: "8px", marginLeft: "20px" }}>P.O. Box 24782, Dubai, U.A.E</p>
                <p className='text-white' style={{ lineHeight: "8px", marginLeft: "20px" }}>CELL: +971 55 993 5515</p>
              </div>

            </div>

            <div class="col-lg-3 col-md-6 mt-5 mt-lg-0 footer-widget">
              <h3 class="widget-title">Services</h3>
              <ul class="list-arrow">

                <li
                  class="nav-item Header "
                  type="button"
                  data-toggle="collapse"
                  data-target=".navbar-collapse"
                  aria-controls="navbar-collapse"
                  aria-label="Toggle navigation"
                >
                  <NavLink
                    to="/services"
                    class="  dropdown-toggle "
                    data-toggle=""
                    aria-current="page"
                    onClick={gotoBtn}
                  >
                    LANDLORD SERVICES
                  </NavLink>
                </li>
                <li
                  class="nav-item Header "
                  type="button"
                  data-toggle="collapse"
                  data-target=".navbar-collapse"
                  aria-controls="navbar-collapse"
                  aria-label="Toggle navigation"
                >
                  <NavLink
                    to="/services"
                    class="  dropdown-toggle "
                    data-toggle=""
                    aria-current="page"
                    onClick={gotoBtn}
                  >
                    BUILDING REMODELS
                  </NavLink>
                </li>
                <li
                  class="nav-item Header "
                  type="button"
                  data-toggle="collapse"
                  data-target=".navbar-collapse"
                  aria-controls="navbar-collapse"
                  aria-label="Toggle navigation"
                >
                  <NavLink
                    to="/services"
                    class="  dropdown-toggle "
                    data-toggle=""
                    aria-current="page"
                    onClick={gotoBtn}
                  >
                    INTERIOR DESIGN
                  </NavLink>
                </li>
                <li
                  class="nav-item Header "
                  type="button"
                  data-toggle="collapse"
                  data-target=".navbar-collapse"
                  aria-controls="navbar-collapse"
                  aria-label="Toggle navigation"
                >
                  <NavLink
                    to="/services"
                    class="  dropdown-toggle "
                    data-toggle=""
                    aria-current="page"
                    onClick={gotoBtnSecond}
                  >
                    EXTERIOR DESIGN
                  </NavLink>
                </li>
                <li
                  class="nav-item Header "
                  type="button"
                  data-toggle="collapse"
                  data-target=".navbar-collapse"
                  aria-controls="navbar-collapse"
                  aria-label="Toggle navigation"
                >
                  <NavLink
                    to="/services"
                    class="  dropdown-toggle "
                    data-toggle=""
                    aria-current="page"
                    onClick={gotoBtnSecond}
                  >
                    RENOVATION
                  </NavLink>
                </li>
                <li
                  class="nav-item Header "
                  type="button"
                  data-toggle="collapse"
                  data-target=".navbar-collapse"
                  aria-controls="navbar-collapse"
                  aria-label="Toggle navigation"
                >
                  <NavLink
                    to="/services"
                    class="  dropdown-toggle "
                    data-toggle=""
                    aria-current="page"
                    onClick={gotoBtnSecond}
                  >
                    SAFETY MANAGEMENT
                  </NavLink>
                </li>
              </ul>
            </div>

          </div>
        </div>
      </div>

      <div class="copyright">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-12">
              <div class="copyright-info text-center">
                <span className='text-white'>Copyright &copy; <script>
                  document.write(new Date().getFullYear())
                </script>, Designed &amp; Developed by <a href="https://royalbengalrealestate.com">Royal Bengal Real Estate L.L.C</a></span>
              </div>
            </div>


          </div>

          <div id="back-to-top" data-spy="affix" data-offset-top="10" class="back-to-top position-fixed">
            <button class="btn btn-primary" title="Back to Top">
              <i class="fa fa-angle-double-up"></i>
            </button>
          </div>

        </div>
      </div>
    </footer>
  );
};

export default Footer;