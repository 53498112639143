import React from 'react';

const FourZeroFour = () => {
     return (
          <div>
               <div className='container '>
                    <div className='row'>
                         <img className='' src='https://i.ibb.co/0FS0CgK/404.jpg'  alt=''/>
                    </div>
               </div>
          </div>
     );
};

export default FourZeroFour;